<template>
  <div>
    <div class="welcome" data-aos="flip-right">
      <!-- 드림플러스 가입을 환영합니다. -->
      <div class="completed">{{ $t('content.auth.SignUpStep4.Title') }}</div>
    </div>

    <div class="btn-wrap">
      <!-- Login 버튼 -->
      <BaseButton
        @click="doNext"
      >{{ $t('content.auth.SignUpStep4.ButtonNext') }}</BaseButton>
    </div>
  </div>
</template>

<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import BaseButton from '@/components/base/BaseButton.vue';
import routeTypes from '@/router/routeTypes';

export default {
  name: 'SignUpStep4',
  mixins: [
    mixinHelperUtils,
  ],
  components: {
    BaseButton,
  },

  props: {
    email: {
      type: String,
      required: false,
    },
    isSns: {
      type: Boolean,
      required: false,
    },
  },

  created() {
    this.setFooter(false);
  },

  mounted() {
    if (!this.email || !this.isSns) {
      this.cancel();
    }
  },

  methods: {
    cancel() {
      this.$router.replace({
        name: routeTypes.ROUTE_NAME_SIGNUP,
      });
    },
    doNext() {
      this.$router.replace({
        name: routeTypes.ROUTE_NAME_SIGNIN,
      });
    },
  },
};
</script>
